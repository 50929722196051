import { Link } from 'gatsby';
import React from 'react';

import Logo from 'assets/svgs/logo.inline.svg';

const Header = () => (
  <header className="header">
    <div className="header__brand d-flex justify-content-center justify-content-md-start">
      <Link to="/">
        <Logo className="header__brand-logo" style={{ width: 64, height: 64 }} />
      </Link>
    </div>
  </header>
);

export default Header;
