import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';

const App = ({ children }) => {
  return (
    <div className="app-content">
      <Header />
      <main>{children}</main>
      {/* <footer></footer> */}
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
